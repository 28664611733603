import { injectable } from "inversify";
import "reflect-metadata";
import RequestMethod from "./requestMethod";

@injectable()
export default class DataService {

    protected async sendData(url: string, method: RequestMethod, data = {}): Promise<boolean> {

        const response = await fetch(url, {
            method: method,
            body: data == null ? null : JSON.stringify(data)
        });
        
        return new Promise((resolve, reject) => {          

            if (response.ok) {

                resolve(true);
            }
            else {

                reject(false);
            }
        });
    }
}