import i18n from 'i18next';
import dataEN from '../../locales/en.json'
import dataPL from '../../locales/pl.json'

const options = {
  
    debug: false,
  
    lng: 'pl',
  
    fallbackLng: 'en',

    resources: Object.assign(dataPL, dataEN),

    initImmediate: false
};

i18n
  .init(options);

export default i18n;