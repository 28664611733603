import { Container } from "inversify";
import TYPES from "./diTypes";
import IMailingService from "./services/IMailingService";
import ContactFormManager from "./contact_connected/contactFormManager";
import NewsletterFormManager from "./contact_connected/newsletterFormManager";
import MailingService from "./services/MailingService";

var container = new Container();
container.bind<IMailingService>(TYPES.IMailingService).to(MailingService);
container.bind<ContactFormManager>(TYPES.ContactFormManager).to(ContactFormManager);
container.bind<NewsletterFormManager>(TYPES.NewsletterFormManager).to(NewsletterFormManager);

export default container;