import i18n from "./i18n/config";
import CookieManager from "./shared/cookieManager";

export default class LanguageSwitcher {

    private readonly _html: HTMLElement;

    constructor() {

        this._html = document.querySelector('html');

        this.registerListeners();

        this.setInitialLanguage();
    }

    private setInitialLanguage(): void {

        const languageCookie = CookieManager.getCookie('site-language');

        if(languageCookie != '') {

            document
            .querySelectorAll(`.c-language-switcher input[value="${languageCookie}"]`)
            .forEach((element) => (element as HTMLInputElement).checked = true);

            this.onLanguageChange(languageCookie);
        }
        else {

            CookieManager.setCookie('site-language', 'pl', 365);
        }
    }

    private registerListeners(): void {

        document
            .querySelectorAll('.c-language-switcher input')
            .forEach((element) => {

                const language = (element as HTMLInputElement).value;

                element.addEventListener('change', () => {

                    CookieManager.setCookie('site-language', language, 365);

                    this.onLanguageChange(language);
                });
            });
    }

    private setProperSwitchersPosition(language: string): void {

        document
                    .querySelectorAll('input.c-language-switcher__input')
                    .forEach((elementToClick) => {

                        const castedElement = (elementToClick as HTMLInputElement);
                        
                        if(castedElement.value == language) {

                            castedElement.checked = true;
                        }
                        else {

                            castedElement.checked = false;
                        }                     
                    });
    }

    private onLanguageChange(language: string): void {

        i18n.changeLanguage(language);

        this.setProperSwitchersPosition(language);

        this._html.lang = language;
    }
}