import CssClasses from "./cssClasses";

export default class CookieManager {

    static manageCookieInfoPopup(): void {

        if(this.getCookie('cookie-info') == '') {

            const container =
             document.querySelector('#cookie-info-popup-container') as HTMLElement;

            this.showCookieInfoPopup(container);
            this.setCookiePopupClickListeners(container);
        }
    }

    private static showCookieInfoPopup(popupContainer: HTMLElement): void {

        popupContainer.classList.remove(CssClasses.OPAQUE_NO_EVENTS);
        popupContainer.setAttribute('aria-hidden', 'false');
    }

    private static setCookiePopupClickListeners(popupContainer: HTMLElement): void {

        document
        .querySelector('#cookie-info-popup-container .c-info-popup-container__popup-close-button')
        .addEventListener('click', () => {

            popupContainer.classList.add(CssClasses.OPAQUE_NO_EVENTS);
            popupContainer.setAttribute('aria-hidden', 'true');
        });

        document
        .querySelector('#cookie-info-popup-container .c-info-popup-container__popup-confirm-button')
        .addEventListener('click', () => {

            popupContainer.classList.add(CssClasses.OPAQUE_NO_EVENTS);
            popupContainer.setAttribute('aria-hidden', 'true');

            this.setCookie('cookie-info', 'accepted', 365);
        });
    }

    /* Function: setCookie

        Tworzy ciasteczko z określonymi parametrami.

        Parametry:

            cname - Nazwa ciasteczka.

            cvalue - Wartość ciasteczka.
            
            exdays - Liczba dni ważności ciasteczka.
    */
    static setCookie(cname: string, cvalue: string, exdays: number): void {

        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

        let  expires = "expires=" + d.toUTCString();

        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /* Function: getCookie

        Zwraca wartość ciasteczka o określonej nazwie.

        Parametry:

            cname - Nazwa ciasteczka.

        Zwraca:

            Wartość ciasteczka o określonej nazwie w przypadku znalezienia danego ciasteczka, a w przeciwnym wypadku pusty string.
    */
    static getCookie(cname: string): string {

        let name = cname + "=";
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {

            let c = ca[i];
            
            while (c.charAt(0) == ' ') {

                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {

                return c.substring(name.length, c.length);
            }
        }
        
        return "";
    }
}