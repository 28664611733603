import CssClasses from "./cssClasses";

export default class FormElementValidator {

    private readonly _emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    private _isNotEmpty: boolean;
    private _isNotLongerThanNumberOfCharacters: boolean;
    private _isProperEmailFormat: boolean;

    constructor(private _element: HTMLInputElement | HTMLTextAreaElement) {

        this._isNotEmpty = true;
        this._isNotLongerThanNumberOfCharacters = true;
        this._isProperEmailFormat = true;
    }

    checkIfNotEmpty(): FormElementValidator {

        if(this._element.value.length == 0) {

            this._element.classList.add(CssClasses.INPUT_ERROR);
            this._isNotEmpty = false;
        }
        else {

            this._element.classList.remove(CssClasses.INPUT_ERROR);
            this._isNotEmpty = true;
        }        

        return this;
    }

    checkIfNotLongerThan(numberOfCharacters: number): FormElementValidator {

        if(this._element.value.length <= numberOfCharacters) {

            this._element.classList.remove(CssClasses.INPUT_ERROR);
            this._isNotLongerThanNumberOfCharacters = true;
        }
        else {

            this._element.classList.add(CssClasses.INPUT_ERROR);
            this._isNotLongerThanNumberOfCharacters = false;
        }

        return this;
    }

    checkEmailFormat(): FormElementValidator {       

        if(this._emailRegex.test(this._element.value.toLowerCase())) {

            this._element.classList.remove(CssClasses.INPUT_ERROR);
            this._isProperEmailFormat = true;
        }
        else {

            this._element.classList.add(CssClasses.INPUT_ERROR);
            this._isProperEmailFormat = false;
        }

        return this;
    }

    isCorrect(): boolean {

        return this._isNotEmpty && this._isNotLongerThanNumberOfCharacters && this._isProperEmailFormat;
    }
}