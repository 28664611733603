export default class LayoutManager {

    static correctLayout(): void {

        let elementsForLayoutCorrection =
        document.querySelectorAll('[data-for_layout_correction]') as unknown as HTMLElement[];

        elementsForLayoutCorrection.forEach((element) => 
            element.style.height = element.firstElementChild.scrollHeight + 'px');
    }
}