import ContactFormManager from "./contact_connected/contactFormManager";
import LayoutManager from "./layoutManager";
import Scroller from "./shared/scroller";
import NewsletterFormManager from "./contact_connected/newsletterFormManager";
import { injectable } from "inversify";
import container from "./inversify.config";
import TYPES from "./diTypes";
import Translator from "./i18n/translator";
import EmailInfoPopupManager from "./contact_connected/emailInfoPopupManager";
import $ from "jquery";
import "slick-carousel";
import Utils from "./shared/utils";
import LanguageSwitcher from "./languageSwitcher";
import CookieManager from "./shared/cookieManager";
import Timer from "./shared/timer";
import CssClasses from "./shared/cssClasses";

@injectable()
class Index {

    private readonly windowInnerHeight: number;

    private _body: HTMLBodyElement;

    private _fixedTopNavigation: HTMLElement;
    private _floatingNavigation: HTMLElement;
    private _floatingNavigationLogotype: HTMLElement;

    private _mainMenu: HTMLElement;
    private _mobileMenuOpenButton: HTMLButtonElement;
    private _mobileMenuCloseButton: HTMLButtonElement;

    private _navSolutionButtons: NodeListOf<HTMLButtonElement>;
    private _navOurJourneyButtons: NodeListOf<HTMLButtonElement>;
    private _navContactUsButtons: NodeListOf<HTMLButtonElement>;
    private _navTeamButtons: NodeListOf<HTMLButtonElement>;

    private _topSection: HTMLElement;
    private _howItWorksSection: HTMLElement;
    private _achievementsSection: HTMLElement;
    private _contactSection: HTMLElement;
    private _teamSection: HTMLElement;

    private _$aboutSystemContainer: any; //JQuery

    private _isMobileWidth = false;

    private readonly MOBILE_WIDTH = 900;

    constructor() {

        this.windowInnerHeight = window.innerHeight;
        
        this.setUpElements();
        this.registerWindowListeners();
        this.registerElementListeners();
    }

    private setUpElements(): void {

        this._body = document.querySelector('body');

        this._fixedTopNavigation = document.querySelector('#fixed-top-navigation');
        this._floatingNavigation = document.querySelector('#floating-navigation');
        this._floatingNavigationLogotype = document.querySelector('#floating-navigation-logotype-helper');

        this._mainMenu = document.querySelector('#main-menu');
        this._mobileMenuOpenButton = document.querySelector('#mobile-menu-open-button');
        this._mobileMenuCloseButton = document.querySelector('#mobile-menu-close-button');

        this._navSolutionButtons = document.querySelectorAll('.nav-solution-button');
        this._navOurJourneyButtons = document.querySelectorAll('.nav-our-journey-button');
        this._navContactUsButtons = document.querySelectorAll('.nav-contact-us-button');
        this._navTeamButtons = document.querySelectorAll('.nav-team-button');

        this._topSection = document.querySelector('#top-container');
        this._howItWorksSection = document.querySelector('#how-it-works-container');
        this._achievementsSection = document.querySelector('#achievements-container');
        this._contactSection = document.querySelector('#contact-form-container');
        this._teamSection = document.querySelector('#team-container');

        this._$aboutSystemContainer = $('#about-system-container');
    }

    private registerWindowListeners(): void {

        window.onload = async () => {

            new EmailInfoPopupManager();

            container.get<NewsletterFormManager>(TYPES.NewsletterFormManager);
            
            container.get<ContactFormManager>(TYPES.ContactFormManager);

            new Translator().translate();

            new LanguageSwitcher();

            LayoutManager.correctLayout();

            this.setUpSlick();

            this.hideLoadingAnimation();

            await Timer.waitForMiliseconds(1000);

            CookieManager.manageCookieInfoPopup();
        };

        window.onscroll = () => {

            Scroller.toggleElementClassOnHeight(
                this._floatingNavigation,
                this.windowInnerHeight - 300,
                CssClasses.OPAQUE_NO_EVENTS);

            Scroller.toggleElementClassOnHeight(
                this._fixedTopNavigation,
                this.windowInnerHeight - 100,
                CssClasses.OPAQUE_NO_EVENTS,
                true);

            this.changeActiveSection();

            this.checkMobileWidth();
        };

        let manageAboutSystemCarouselLayout = 
        Utils.debounce(() => {

            this._$aboutSystemContainer.slick('unslick');
            this.setUpSlick();
        }, 250); 

        window.onresize = () => {

            LayoutManager.correctLayout();
            this.checkMobileWidth();
            manageAboutSystemCarouselLayout();            
        }
    }

    private registerElementListeners(): void {

        this._mobileMenuOpenButton.addEventListener('click', () => {

            this._mainMenu.classList.add(CssClasses.MOBILE_ACTIVE);

            if(this._isMobileWidth) {

                this._body.classList.add(CssClasses.OVERFLOW_Y_HIDDEN);
            }
        });

        this._mobileMenuCloseButton.addEventListener('click', () => {

            this._mainMenu.classList.remove(CssClasses.MOBILE_ACTIVE);

            if(this._isMobileWidth) {

                this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
            }
        });

        this._floatingNavigationLogotype.addEventListener('click', () => {
            
            Scroller.scrollToElement(this._topSection);
        });

        this._navSolutionButtons.forEach((element) => {

            element.addEventListener('click', () => {
                
                Scroller.scrollToElement(this._howItWorksSection, this._isMobileWidth ? 0 : 60);
                this._mainMenu.classList.remove(CssClasses.MOBILE_ACTIVE);
                this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
            });
        });

        this._navOurJourneyButtons.forEach((element) => {

            element.addEventListener('click', () => {

                Scroller.scrollToElement(this._achievementsSection, this._isMobileWidth ? 30 : 100);
                this._mainMenu.classList.remove(CssClasses.MOBILE_ACTIVE);
                this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
            });
        });

        this._navContactUsButtons.forEach((element) => {

            element.addEventListener('click', () => {

                Scroller.scrollToElement(this._contactSection, this._isMobileWidth ? 20 : 60);
                this._mainMenu.classList.remove(CssClasses.MOBILE_ACTIVE);
                this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
            });
        });

        this._navTeamButtons.forEach((element) => {

            element.addEventListener('click', () => {
  
                Scroller.scrollToElement(this._teamSection, this._isMobileWidth ? -50 : 0);
                this._mainMenu.classList.remove(CssClasses.MOBILE_ACTIVE);
                this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
            });
        });
    }

    private changeActiveMenuItem(elements: NodeListOf<HTMLButtonElement>): void {

        this.removeMenuitemSelection();
        
        elements.forEach((element) => element.classList.add('c-main-navigation__menu-list-item-button--selected'));
    }

    private removeMenuitemSelection(): void {

        document.querySelectorAll('.c-main-navigation__menu-list-item-button--selected')
        .forEach((element) => element.classList.remove('c-main-navigation__menu-list-item-button--selected'));
    }

    private changeActiveSection() {
        
        if(Scroller.passedGivenHeight(this._howItWorksSection.offsetTop - 64)) {

            this.changeActiveMenuItem(this._navSolutionButtons);
        }
        
        if(Scroller.passedGivenHeight(this._achievementsSection.offsetTop - 120)) {

            this.changeActiveMenuItem(this._navOurJourneyButtons);
        }

        if(Scroller.passedGivenHeight(this._contactSection.offsetTop - 120)) {

            this.changeActiveMenuItem(this._navContactUsButtons);
        }

        if(Scroller.passedGivenHeight(this._teamSection.offsetTop - 120)) {

            this.changeActiveMenuItem(this._navTeamButtons);
        }
    }

    private checkMobileWidth(): void {

        if(window.innerWidth <= this.MOBILE_WIDTH) {

            this._isMobileWidth = true;
        }
        else {

            this._isMobileWidth = false;
        }
    }

    private hideLoadingAnimation(): void {

        document.querySelector('#spinner')
                .classList.add(CssClasses.OPAQUE_NO_EVENTS);

        this._body.classList.remove(CssClasses.OVERFLOW_Y_HIDDEN);
    }

    private setUpSlick(): void {

        this._$aboutSystemContainer
            .slick({
                infinite: false,
                slidesToShow: this.getAppropriateNumberOfSlickSlides(),
                arrows: false,
                dots: true
            });
    }

    private getAppropriateNumberOfSlickSlides(): number {

        if(window.innerWidth >= 1520) {

            return 3;
        }
        else if (window.innerWidth < 1520 && window.innerWidth >= 1100)  {

            return 2;
        }
        else {

            return 1;
        }
    }
}

new Index();