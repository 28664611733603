import DataService from "./dataService";
import IMailingService from "./IMailingService";
import { injectable } from "inversify";
import "reflect-metadata";
import RequestMethod from "./requestMethod";

@injectable()
export default class MailingService extends DataService implements IMailingService {

    sendEmailFromContactFrom(email: string, name: string, message: string, language: string): Promise<boolean> {
        
        return this.sendData(
            '/contact',
            RequestMethod.POST,
            { email, name, message, language }
        );
    }

    sendEmailFromNewsletterForm(email: string, name: string): Promise<boolean> {

        return this.sendData(
            '/subscribe-newsletter',
            RequestMethod.PUT,
            { email, name }
        );
    }
}