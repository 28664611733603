import ResponseStatus from "./responseStatus";
import Timer from "../shared/timer";

export default class EmailInfoPopupManager {

    private _popupSection: HTMLElement;
    private _popup: HTMLElement;
    private _successTitleContainer: HTMLElement;
    private _errorTitleContainer: HTMLElement;
    private _successMessageContainer: HTMLElement;
    private _errorMessageContainer: HTMLElement;

    constructor() {

        this._popupSection = document.querySelector('#email-info-popup-container');
        this._popup = document.querySelector('#email-info-popup');
        this._successTitleContainer = document.querySelector('#info-popup-success-title');
        this._errorTitleContainer = document.querySelector('#info-popup-error-title');
        this._successMessageContainer = document.querySelector('#info-popup-success-message');
        this._errorMessageContainer = document.querySelector('#info-popup-error-message');

        document
            .querySelectorAll('#email-info-popup .c-info-popup-container__popup-close-button, ' +
            '#email-info-popup .c-info-popup-container__popup-confirm-button')
            .forEach((element) => {

                element.addEventListener('click', async () => {

                    this._popupSection.classList.add('opaque-no-events');
                    this._popupSection.setAttribute('aria-hidden', 'true');
    
                    await Timer.waitForMiliseconds(700);
    
                    this._successTitleContainer.classList.add('d-none');
                    this._errorTitleContainer.classList.add('d-none');
                    this._successMessageContainer.classList.add('d-none');
                    this._errorMessageContainer.classList.add('d-none');
                });
            });           
    }

    showPopup(status: ResponseStatus): void {

        switch (status) {

            case ResponseStatus.SUCCESS:
                
                this._popup.setAttribute('aria-describedby', this._successMessageContainer.id);
                this._successTitleContainer.classList.remove('d-none');
                this._successMessageContainer.classList.remove('d-none');
                break;

            case ResponseStatus.ERROR:
            
                this._popup.setAttribute('aria-describedby', this._errorMessageContainer.id);
                this._errorTitleContainer.classList.remove('d-none');
                this._errorMessageContainer.classList.remove('d-none');
                break;
        }

        this._popupSection.setAttribute('aria-hidden', 'false');
        this._popupSection.classList.remove('opaque-no-events');
    }
}