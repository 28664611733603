import FormElementValidator from "../shared/formElementValidator";
import Timer from "../shared/timer";
import { inject, injectable } from "inversify";
import "reflect-metadata";
import TYPES from "../diTypes";
import IMailingService from "../services/IMailingService";
import EmailInfoPopupManager from "./emailInfoPopupManager";
import ResponseStatus from "./responseStatus";
import Translator from "../i18n/translator";
import CssClasses from "../shared/cssClasses";

@injectable()
export default class ContactFormManager {

    private _formElementValidators: FormElementValidator[];

    private readonly _html: HTMLElement;

    private readonly _emailInput: HTMLInputElement;
    private readonly _nameInput: HTMLInputElement;
    private readonly _messageTextArea: HTMLTextAreaElement;
    private readonly _submitButton: HTMLButtonElement;
    private readonly _form: HTMLFormElement;
    private readonly _firstStain: HTMLImageElement;
    private readonly _secondStain: HTMLImageElement;

    private readonly _emailValidator: FormElementValidator;
    private readonly _nameValidator: FormElementValidator;
    private readonly _messageValidator: FormElementValidator;

    private readonly _infoPopupManager: EmailInfoPopupManager;

    private readonly _mailingService: IMailingService;

    constructor(@inject(TYPES.IMailingService) mailingService: IMailingService) {

        this._html = document.querySelector('html');

        this._form = document.querySelector('#contact-form');
        this._emailInput =
            this._form.querySelector('.c-contact-form-container__input[name="email"]');
        this._nameInput =
            this._form.querySelector('.c-contact-form-container__input[name="name"]');
        this._messageTextArea =
            this._form.querySelector('.c-contact-form-container__textarea[name="message"]');
        this._submitButton = 
            this._form.querySelector('.c-contact-form-container__submit-button');
        
        this._firstStain =
            document.querySelector('#contact-form-container .c-contact-form-container__stain--first');
        this._secondStain =
            document.querySelector('#contact-form-container .c-contact-form-container__stain--second');

        this._emailValidator = new FormElementValidator(this._emailInput);
        this._nameValidator = new FormElementValidator(this._nameInput);
        this._messageValidator = new FormElementValidator(this._messageTextArea);

        this._infoPopupManager = new EmailInfoPopupManager();

        this._formElementValidators = [
            this._emailValidator,
            this._nameValidator,
            this._messageValidator
        ];

        this._mailingService = mailingService;

         this.setUpListeners();
    }

    private setUpListeners(): void {

        this._emailInput.addEventListener('focus', () => {
                
            this._firstStain.classList.add('c-contact-form-container__stain--first-state-one');
            this._secondStain.classList.add('c-contact-form-container__stain--second-state-one');
        });

        this._emailInput.addEventListener('blur', () => {

            this._firstStain.classList.remove('c-contact-form-container__stain--first-state-one');
            this._secondStain.classList.remove('c-contact-form-container__stain--second-state-one');
            
            this._emailValidator
                .checkIfNotLongerThan(50)
                .checkIfNotEmpty()
                .checkEmailFormat();
        });

        this._nameInput.addEventListener('focus', () => {
                
            this._firstStain.classList.add('c-contact-form-container__stain--first-state-two');
            this._secondStain.classList.add('c-contact-form-container__stain--second-state-two');
        });

        this._nameInput.addEventListener('blur', () => {
            
            this._firstStain.classList.remove('c-contact-form-container__stain--first-state-two');
            this._secondStain.classList.remove('c-contact-form-container__stain--second-state-two');

            this._nameValidator
                .checkIfNotLongerThan(50)
                .checkIfNotEmpty();
        });

        this._messageTextArea.addEventListener('focus', () => {
                
            this._firstStain.classList.add('c-contact-form-container__stain--first-state-three');
            this._secondStain.classList.add('c-contact-form-container__stain--second-state-three');
        });

        this._messageTextArea.addEventListener('blur', () => {
            
            this._firstStain.classList.remove('c-contact-form-container__stain--first-state-three');
            this._secondStain.classList.remove('c-contact-form-container__stain--second-state-three');

            this._messageValidator
                .checkIfNotLongerThan(300)           
                .checkIfNotEmpty();
        });

        this._form.addEventListener('submit', (event: Event) => this.onFormSubmit(event));
    }

    private async onFormSubmit(event: Event): Promise<void> {

        event.preventDefault();

        this._formElementValidators.forEach((validator) => validator.checkIfNotEmpty());

        const isFormValid =
         this._formElementValidators.filter((validator) => validator.isCorrect()).length ==
          this._formElementValidators.length;

        if(isFormValid) {
            
            this.sendEmail();
        }
        else {

            this.activateFormErrorBlink();
        }
    }

    private async sendEmail(): Promise<void> {

        try {

            this._submitButton.innerText = Translator.getTranslatedText('sending');
            this._submitButton.disabled = true;

            const sendResult =
             await this._mailingService.sendEmailFromContactFrom(
                this._emailInput.value,
                this._nameInput.value,
                this._messageTextArea.value,
                this._html.lang
            );
            
            if(sendResult == true) {

                this.clearFormValues();

                this._infoPopupManager.showPopup(ResponseStatus.SUCCESS);
            }
            else {

                this._submitButton.innerText = Translator.getTranslatedText('contact.sendButtonText');
                this._submitButton.disabled = false;

                this._infoPopupManager.showPopup(ResponseStatus.ERROR);
            }
        }
        catch (error) {

            this._submitButton.innerText = Translator.getTranslatedText('contact.sendButtonText');
            this._submitButton.disabled = false;

            this._infoPopupManager.showPopup(ResponseStatus.ERROR);
        }
    }

    private activateFormErrorBlink(): void{

        this._form.querySelectorAll('.input-error').forEach(async (element: HTMLElement) => {
                
            await Timer.waitForMiliseconds(1000);

            element.classList.remove(CssClasses.INPUT_ERROR);

            await Timer.waitForMiliseconds(1000);

            element.classList.add(CssClasses.INPUT_ERROR);
        });
    }

    private clearFormValues(): void {

        this._emailInput.value = '';
        this._nameInput.value = '';
        this._messageTextArea.value = '';
        this._submitButton.innerText = Translator.getTranslatedText('contact.sendButtonText');
        this._submitButton.disabled = false;
    }
}