import FormElementValidator from "../shared/formElementValidator";
import Timer from "../shared/timer";
import { inject, injectable } from "inversify";
import "reflect-metadata";
import TYPES from "../diTypes";
import IMailingService from "../services/IMailingService";
import EmailInfoPopupManager from "./emailInfoPopupManager";
import ResponseStatus from "./responseStatus";
import Translator from "../i18n/translator";
import CssClasses from "../shared/cssClasses";

@injectable()
export default class NewsletterFormManager {

    private readonly _emailInput: HTMLInputElement;
    private readonly _nameInput: HTMLInputElement;
    private readonly _form: HTMLFormElement;
    private readonly _submitButton: HTMLButtonElement;

    private _emailValidator: FormElementValidator;
    private _nameValidator: FormElementValidator;

    private _formElementValidators: FormElementValidator[];

    private readonly _mailingService: IMailingService;

    private readonly _infoPopupManager: EmailInfoPopupManager;

    constructor(@inject(TYPES.IMailingService) mailingService: IMailingService) {

        this._emailInput = document.querySelector('#newsletter-email-input');
        this._nameInput = document.querySelector('#newsletter-name-input');
        this._form = document.querySelector('#newsletter-form');
        this._submitButton = document.querySelector('#newsletter-email-confirm-button');

        this._emailValidator = new FormElementValidator(this._emailInput);
        this._nameValidator = new FormElementValidator(this._nameInput);

        this._formElementValidators = [
            this._emailValidator,
            this._nameValidator
        ];

        this._mailingService = mailingService;

        this._infoPopupManager = new EmailInfoPopupManager();

        this.setUpListeners();
    }

    private setUpListeners(): void {

        this._emailInput.addEventListener('blur', () => {
            
            this._emailValidator
                .checkIfNotLongerThan(50)
                .checkIfNotEmpty()               
                .checkEmailFormat();
        });

        this._nameInput.addEventListener('blur', () => {

            this._nameValidator
                .checkIfNotLongerThan(50)
                .checkIfNotEmpty();
        });

        this._form.addEventListener('submit', (event) => this.onFormSubmit(event));
    }

    private onFormSubmit(event: Event): void {

        event.preventDefault();

        this._formElementValidators.forEach((validator) => validator.checkIfNotEmpty());

        const isFormValid =
         this._formElementValidators.filter((validator) => validator.isCorrect()).length ==
          this._formElementValidators.length;

        if(isFormValid) {

            this.sendEmail();
        }
        else {

            this.activateFormErrorBlink();
        }
    }

    private async sendEmail(): Promise<void> {

        try {

            this._submitButton.innerText = Translator.getTranslatedText('sending');
            this._submitButton.disabled = true;

            const sendResult =
             await this._mailingService.sendEmailFromNewsletterForm(
                this._emailInput.value,
                this._nameInput.value
            );
            
            if(sendResult == true) {

                this._emailInput.value = '';
                this._submitButton.innerText = Translator.getTranslatedText('header.emailConfirmButtonText');
                this._submitButton.disabled = false;

                this._infoPopupManager.showPopup(ResponseStatus.SUCCESS);
            }
            else {
                
                this._submitButton.innerText = Translator.getTranslatedText('header.emailConfirmButtonText');
                this._submitButton.disabled = false;

                this._infoPopupManager.showPopup(ResponseStatus.ERROR);
            }
        }
        catch (error) {

            this._submitButton.innerText = Translator.getTranslatedText('header.emailConfirmButtonText');
            this._submitButton.disabled = false;

            this._infoPopupManager.showPopup(ResponseStatus.ERROR);
        }
    }

    private activateFormErrorBlink(): void{

        this._form.querySelectorAll('.input-error').forEach(async (element: HTMLElement) => {
                
            await Timer.waitForMiliseconds(1000);

            element.classList.remove(CssClasses.INPUT_ERROR);

            await Timer.waitForMiliseconds(1000);

            element.classList.add(CssClasses.INPUT_ERROR);
        });
    }
}