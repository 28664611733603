import i18n from "./config";

export default class Translator {

    private _elementsWithTextToTranslate: NodeListOf<HTMLElement>;
    private _elementsWithPlaceholderToTranslate: NodeListOf<HTMLElement>;
    private _elementsWithAltToTranslate: NodeListOf<HTMLElement>;
    private _pageTitle: HTMLTitleElement;
    private _metaDescription: HTMLMetaElement;

    constructor() {
      
        this._elementsWithTextToTranslate = document.querySelectorAll('[data-i18n_text]');
        this._elementsWithPlaceholderToTranslate = document.querySelectorAll('[data-i18n_placeholder]');
        this._elementsWithAltToTranslate = document.querySelectorAll('[data-i18n_alt]');
        this._pageTitle = document.querySelector('title');
        this._metaDescription = document.querySelector('meta[name="description"]');

        this.setUpLanguageChangedListener();
    }

    translate(): void {

        this._elementsWithTextToTranslate.forEach((element) => {
            
            element.innerHTML = i18n.t(element.dataset.i18n_text);
        });

        this._elementsWithPlaceholderToTranslate.forEach((element) => {

            element.setAttribute('placeholder', i18n.t(element.dataset.i18n_placeholder));
        });

        this._elementsWithAltToTranslate.forEach((element) => {

            element.setAttribute('alt', i18n.t(element.dataset.i18n_alt));
        });

        this._pageTitle.innerText = i18n.t('pageTitle');
        this._metaDescription.setAttribute('content', i18n.t('metaDescription'));
    }

    static getTranslatedText(resourceString: string): string {

        return i18n.t(resourceString);
    }

    private setUpLanguageChangedListener(): void {

        i18n.on('languageChanged', () => this.translate());
    }
}